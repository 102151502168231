import React, { useState, useEffect } from "react";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import "./App.scss";
import { LiffProvider } from "react-liff";
import Login from "./pages/Login/Login";
import { Route, Routes, useRoutes } from "react-router-dom";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

function App() {
  const liffId = process.env.REACT_APP_LINE_LIFF_ID;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LiffProvider {...{ liffId }}>
        <div className="App">
          <Login onLoginSuccess={() => setIsLoggedIn(true)} />
        </div>
      </LiffProvider>
    </ThemeProvider>
  );
}

export default App;
