import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import "./Score.scss";

const Score = ({ answerHistory }) => {
  const [score, setScore] = useState(0);

  const [totalScore, setTotalScore] = useState(0);

  useEffect(() => {
    calculateScore();
  }, [answerHistory]);

  const calculateScore = () => {
    let totalScore = 0;
    answerHistory.forEach((answer) => {
      if (answer.is_correct) {
        totalScore++;
      }
    });
    setScore(totalScore);
    setTotalScore(answerHistory.length);
  };

  return (
    <div className="score-container">
      <Typography variant="h6" id="scoreDisplay">
        總分: {score} / {totalScore}
      </Typography>
    </div>
  );
};

export default Score;
