import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography } from "@material-ui/core";
import "./AnswerItem.scss";
const useStyles = makeStyles({
  answerItem: {
    marginBottom: 10,
  },
  correct: {
    color: "green",
  },
  incorrect: {
    color: "red",
  },
});

const AnswerItem = ({ question, correctAnswer, userAnswer, isCorrect }) => {
  const classes = useStyles();
  console.log("question", question);
  return (
    <Card className="answer-item">
      <CardContent>
        <Typography variant="h6">{question}</Typography>
        <Typography>正確答案: {correctAnswer}</Typography>
        <Typography>回答: {userAnswer}</Typography>
        <Typography className={isCorrect ? classes.correct : classes.incorrect}>
          {isCorrect ? "正確" : "錯誤"}
        </Typography>
      </CardContent>
    </Card>
  );
};

AnswerItem.propTypes = {
  question: PropTypes.string.isRequired,
  correctAnswer: PropTypes.string.isRequired,
  userAnswer: PropTypes.string.isRequired,
  isCorrect: PropTypes.bool.isRequired,
};

export default AnswerItem;
