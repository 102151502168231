import React, { useState, useEffect } from "react";
import { fetchQuestions, submitAnswers } from "../../services/question-api";
import QuestionCard from "../QuestionCard/QuestionCard";
import "./QuestionPage.scss";
import AnswerHistory from "../../pages/AnswerHistory/AnswerHistory";
import { useLiff } from "react-liff";

const QuestionPage = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [answerHistory, setAnswerHistory] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const { isLoggedIn, liff } = useLiff();
  const [userID, setUserID] = useState("");
  const LIMIT_PER_PAGE = 5;
  useEffect(() => {
    fetchQuestions().then((fetchedQuestions) => {
      setQuestions(fetchedQuestions.data);
    });
  }, []);
  useEffect(() => {
    const fetchLineProfile = async () => {
      try {
        const { userId } = await liff.getProfile();
        if (userId) {
          setUserID(userId);
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (isLoggedIn) {
      fetchLineProfile();
    }
  }, [liff, isLoggedIn]);
  const handleOptionChange = (questionId, selectedOption) => {
    var idx = questions.findIndex((question) => question.id === questionId);
    questions[idx].selected_option = selectedOption;
    setUserAnswers({
      ...userAnswers,
      [questionId]: {
        selected_option: selectedOption,
        question: questions[idx].question,
        correct_option: questions[idx].answer,
      },
    });
  };

  const handleRestart = () => {
    // refresh the page
    window.location.reload();
  };

  const handleSubmit = () => {
    if (Object.keys(userAnswers).length === questions.length) {
      if (userID) {
        submitAnswers(userAnswers, userID);
        setSubmitted(true);
      } else {
        alert("Please login to submit");
      }
    } else {
      alert("Please answer all questions before submitting.");
    }
  };

  const handleNextPage = () => {
    setCurrentQuestionIndex(currentQuestionIndex + LIMIT_PER_PAGE);
  };

  const handlePreviousPage = () => {
    setCurrentQuestionIndex(currentQuestionIndex - LIMIT_PER_PAGE);
  };
  const TITLE = process.env.REACT_APP_TITLE;
  const SUBTITLE = process.env.REACT_APP_SUBTITLE;
  const LEADERBOARD = process.env.REACT_APP_LEADERBOARD;

  const NEXT = process.env.REACT_APP_NEXT;
  const SUBMIT = process.env.REACT_APP_SUBMIT;
  const RESTART = process.env.REACT_APP_RESTART;
  return (
    <div className="question-page">
      {!submitted ? (
        <>
          <div className="title">{TITLE}</div>
          <div className="title3">{SUBTITLE}</div>
          {questions
            .slice(currentQuestionIndex, currentQuestionIndex + LIMIT_PER_PAGE)
            .map((question) => (
              <QuestionCard
                key={question.id}
                question={question}
                handleOptionChange={handleOptionChange}
              />
            ))}
          <div className="question-page">
            {currentQuestionIndex > 0 && (
              <button onClick={handlePreviousPage}>Previous</button>
            )}
            {currentQuestionIndex + LIMIT_PER_PAGE < questions.length ? (
              <button className="submit-button" onClick={handleNextPage}>
                {NEXT}
              </button>
            ) : (
              <button className="submit-button" onClick={handleSubmit}>
                {SUBMIT}
              </button>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="title4">{LEADERBOARD}</div>

          <AnswerHistory
            answerHistory={questions}
            onAnswerHistoryUpdate={setAnswerHistory}
          />
          <button className="submit-button" onClick={handleRestart}>
            {RESTART}
          </button>
        </>
      )}
    </div>
  );
};

export default QuestionPage;
