import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;
const APP_PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
const APP_BOT_ID = process.env.REACT_APP_BOT_ID;
const APP_GAME_ID = process.env.REACT_APP_GAME_ID;
export const fetchQuestions = async (examId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/exam/questions`, {
      project_id: APP_PROJECT_ID,
      questions_num: 5,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching questions:", error);
    return [];
  }
};

export const submitAnswers = async (questions, userId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/exam/submit`, {
      metadata: questions,
      user_id: userId,
      bot_id: APP_BOT_ID,
      game_id: APP_GAME_ID,
    });
    return response.data;
  } catch (error) {
    console.error("Error submitting answers:", error);
    return null;
  }
};
