import React from "react";
import {
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import "./QuestionCard.scss";

const QuestionCard = ({ question, selectedOption, handleOptionChange }) => {
  const { id } = question;

  return (
    <Card className="question-card " id={`questionCard-${id}`}>
      <CardContent>
        <Typography variant="h6" component="h2" align="left">
          {question.question}
        </Typography>
        <RadioGroup
          value={selectedOption}
          onChange={(event) => handleOptionChange(id, event.target.value)}
        >
          <FormControlLabel
            key={"A"}
            value={"A"}
            control={<Radio />}
            label={`${question.option_a}`}
            labelPlacement="end"
          />
          <FormControlLabel
            key={"B"}
            value={"B"}
            control={<Radio />}
            label={`${question.option_b}`}
            labelPlacement="end"
          />
          {/* <FormControlLabel
            key={"C"}
            value={"C"}
            control={<Radio />}
            label={`${question.option_c}`}
            labelPlacement="end"
          />
          <FormControlLabel
            key={"D"}
            value={"D"}
            control={<Radio />}
            label={`${question.option_d}`}
            labelPlacement="end"
          /> */}
        </RadioGroup>
      </CardContent>
    </Card>
  );
};

export default QuestionCard;
