import React, { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import AnswerItem from "./AnswerItem/AnswerItem";
import answer from "../../seed/answer.json";
import { fetchAnswerHistory } from "../../services/api";
import Score from "./Score/Score";
import { useLiff } from "react-liff";

const AnswerHistory = () => {
  const { isLoggedIn, liff } = useLiff();
  const [answerHistory, setAnswerHistory] = useState([]);
  const [userID, setUserID] = useState("");
  useEffect(() => {
    const fetchLineProfile = async () => {
      try {
        const { userId } = await liff.getProfile();
        if (userId) {
          setUserID(userId);
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (isLoggedIn) {
      console.log("fetching line profile");
      fetchLineProfile();
    }
  }, [liff, isLoggedIn]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchAnswerHistory(userID);
      setAnswerHistory(data.data);
    };

    fetchData();
  }, [userID]);
  // 從answer中隨機選出一個題目
  const randomIndex = Math.floor(Math.random() * answer.length);
  const randomQuestion = answer[randomIndex];

  return (
    <Container>
      {userID ? (
        <>
          <Score answerHistory={answerHistory} />

          {/* {answerHistory?.map((item, index) => (
            <AnswerItem
              key={index}
              question={item.question}
              correctAnswer={item.correct_option}
              userAnswer={item.selected_option}
              isCorrect={item.is_correct}
            />
          ))} */}
        </>
      ) : (
        <></>
      )}
      <div className="content2">{randomQuestion.content}</div>
    </Container>
  );
};

export default AnswerHistory;
