import React, { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import "./Question.scss";
import QuestionPage from "../../components/QuestionLayout/QuestionPage";
//import { fetchQuestions, submitAnswers } from "../../../services/api";

const Question = () => {
  const [questions, setQuestions] = useState([]);
  const [userAnswers, setUserAnswers] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchExamQuestions();
  }, []);

  const fetchExamQuestions = async () => {
    // const fetchedQuestions = await fetchQuestions();
    // setQuestions(fetchedQuestions);
  };

  const handleOptionChange = (questionId, selectedOption) => {
    // setUserAnswers({ ...userAnswers, [questionId]: selectedOption });
  };

  const handleSubmit = async () => {
    await submitAnswers(userAnswers);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <Container maxWidth="md">
      {questions ? (
        <>
          {" "}
          <QuestionPage
            questions={questions.slice((currentPage - 1) * 5, currentPage * 5)}
            onOptionChange={handleOptionChange}
            onSubmit={handleSubmit}
            onPageChange={handlePageChange}
            currentPage={currentPage}
          />{" "}
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default Question;
