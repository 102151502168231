import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLiff } from "react-liff";
import Question from "../Question/Question";
import "./Login.scss";

const useStyles = makeStyles({
  loginButton: {
    backgroundColor: "#FF9823",
    fontSize: "1.2rem",
    color: "white",
    "&:hover": {
      backgroundColor: "#00e600",
    },
  },
});

const Login = () => {
  const classes = useStyles();
  const { isLoggedIn, liff } = useLiff();
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLogin = () => {
    if (!isLoggedIn) {
      liff.login({});
    }
  };
  useEffect(() => {
    if (isLoggedIn) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [isLoggedIn]);
  const TITLE = process.env.REACT_APP_TITLE;
  return (
    <>
      {!loggedIn && (
        <div className="login-container">
          <>
            <div className="title">{TITLE}</div>
            <div className="title2">問答挑戰</div>
            <div className="sub-title">來開始挑戰吧!</div>
            <div className="sub-title">請選出覺得最正確的答案!</div>
            <div className="button-wrapper">
              <Button
                id="login-button"
                className={`${classes.loginButton} line-break`}
                variant="contained"
                onClick={handleLogin}
              >
                開始挑戰
              </Button>
            </div>
            <div className="content">(請先閱讀完狂客攻略首部曲)</div>
          </>
        </div>
      )}

      {loggedIn && <Question />}
    </>
  );
};

export default Login;
