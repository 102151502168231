import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;
const APP_BOT_ID = process.env.REACT_APP_BOT_ID;
const APP_GAME_ID = process.env.REACT_APP_GAME_ID;

export const fetchAnswerHistory = async (userId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/exam/history`, {
      user_id: userId,
      bot_id: APP_BOT_ID,
      game_id: APP_GAME_ID,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching answer history:", error);
    throw error;
  }
};

export const fetchLeaderboard = async (userId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/leaderboard`, {
      user_id: userId,
      botID: APP_BOT_ID,
      gameID: APP_GAME_ID,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching answer history:", error);
    throw error;
  }
};
export const login = async (accessToken) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/login`, { accessToken });
    return response.data;
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};
